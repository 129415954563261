<template>
  <div>
    <!-- #page-header -->
    <div
      id="page-header"
      class="cover -small"
      data-image-src="assets/img/bg/kontakt.jpg"
    >
      <div class="container">
        <div class="-wrapper">
          <div class="_inner">
            <h1 v-translate>Všeobecné zásady ochrany osobných údajov</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- /#page-header -->

    <!-- .section -->
    <section class="section">
      <div class="container">
        <div v-translate>terms_privacy_all</div>
      </div>
    </section>
    <!-- /.section -->
  </div>
</template>

<script>
export default {
  name: "terms_privacy",
  metaInfo() {
    return {
      title: this.$gettext("terms_privacy_title"),
      titleTemplate: "%s | Webpartner",
      htmlAttrs: {
        lang: "sk-SK",
        amp: undefined,
      },
      link: [
        {
          rel: "canonical",
          href: "https://www.webglobe.sk/upload/700-zasady-ochrany-osobnych-udajov.pdf",
        },
      ],
    };
  },
};
</script>
